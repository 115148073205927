export default class LostKeyForm {
    static send() {
        if ($(".lost-key-form").length) {
            let form = new FormData($("form.lost-key-form")[0]),
                button = $(".lost_key__send"),
                sendSuccessful = (button.length && button.hasClass("success"));

            form.append("action", "lost_key_send");
            if (typeof woocommerce_params.ajax_url !== "undefined" && !sendSuccessful) {
                button.addClass("sending");
                $(".contact__error-field").html("");

                $.ajax({
                    url: woocommerce_params.ajax_url,
                    type: "POST",
                    dataType: "JSON",
                    processData: false,
                    contentType: false,
                    data: form,
                    success: (response) => {
                        if (response.success) {
                            button.addClass("success");
                            if (response.message) {
                                $(".response-message").html(response.message);
                            }
                        } else if (!response.success) {
                            button.removeClass("sending");
                            if (typeof response.error === "object") {
                                let countFields = Object.keys(response.error).length,
                                    i = 0;

                                $.each(response.error, (key, message) => {
                                    i++;

                                    let keyBlock = $('[name=' + key + ']');

                                    if (keyBlock.length) {
                                        let parentBlock = keyBlock.closest(".contact__input-block"),
                                            errorMessageBlock = parentBlock.find(".contact__error-field");

                                        if (parentBlock.length) {
                                            if (!parentBlock.hasClass("error")) {
                                                parentBlock.addClass("error");
                                            }
                                        }
                                        else {
                                            if (!keyBlock.hasClass("error")) {
                                                keyBlock.addClass("error");
                                            }
                                        }

                                        if (errorMessageBlock.length) {
                                            errorMessageBlock.html(message);
                                        }
                                    }
                                })
                                ;

                                if (i === countFields) {
                                    let firstError = $(".error:first:visible");

                                    if (firstError.length) {
                                        $("html, body").animate({
                                            scrollTop: (firstError.offset().top - 100)
                                        }, 1000, () => {
                                            if (firstError.find("input").length) {
                                                firstError.find("input").focus();
                                            }
                                        });
                                    }
                                }
                            } else if (typeof response.error === "string") {
                                $(".response-message").html(response.error).show();
                            }
                        }
                    }
                })
                ;
            }

        } else {
            return false;
        }
    }
}
