import 'slick-carousel';

import ContactForm from '../classes/contact-form';
import LostKeyForm from '../classes/lost-key-form';
import SimpleLightbox from '../classes/simplelightbox'

export default {
    init() {
        new SimpleLightbox('.gallery a', {});
        new SimpleLightbox('.gallery-page .blocks-gallery-item a', {});

        $('.slider-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.slider-nav'
        });
        $('.slider-nav').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            arrows: false,
            dots: false,
            centerMode: false,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                },
            ]
        });

        let indexSlider = $('.index-slider');
        if(indexSlider.length){
            indexSlider.slick({
                arrows: true,
                dots: true,
                nextArrow: '<button type="button" class="next"><i class="fas fa-chevron-right"></i></button>',
                prevArrow: '<button type="button" class="prev"><i class="fas fa-chevron-left"></i></button>',
            });
        }

        if ($(window).width() < 980) {
            $(document)
                .on('click touch', '.nav > li', function (e) {
                    if ($(this).closest('li').children('.drop-level').length) {
                        e.preventDefault();
                        $(this).closest('li').children('.drop-level').slideToggle();
                    }

                });
            $(document).on('click touch' , '.menu-item' , function(){
                if($('.menu-item').hasClass('menu-item-has-children')){
                    $(this).children('.sub-menu').slideToggle();
                    console.log('go');
            }
            })
        }

        $('.pwb-filter-products label').append("<span></span>");
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(document)
            .on('click touch', '.hamburger-button', function () {
                $('header .nav , .hamburger-button').toggleClass('active');
            })
            .on('click touch', '.item-remove', function () {
                $(this).closest('tr').remove();
            })
            .on('click touch', '.popup-close', function () {
                $('.base-popup').fadeOut();
            })
            .on('click touch', '.lupe-link', function (e) {
                e.preventDefault();
                $('.index-search-box').toggleClass('active');
                $('.index-search-box #woocommerce-product-search-field').focus();
            })
            .on('click touch', '.first-level li', function (e) {
                $(this).toggleClass('active');
                if ($(this).closest('li').children('ul').length) {
                    e.preventDefault();
                    $(this).closest('li').children('.second-level').slideToggle();
                }
            })
            .on('click touch', '.widgettitle', function (e) {
                if ($(window).width() < 980 && $(e.target).is('h2')) {
                    $(this).closest('.categories-box').find('.product-categories').slideToggle();
                    $(this).closest('.categories-box').find('.pwb-filter-products').slideToggle();
                }
            })
            .on('click', '.contact__send', (e) => {
                e.preventDefault();

                if (typeof woocommerce_params !== 'undefined') {
                    ContactForm.send();
                }
            })
            .on('click', '.lost_key__send', (e) => {
                e.preventDefault();

                if (typeof woocommerce_params !== 'undefined') {
                    LostKeyForm.send();
                }
            })
            .on('change', '.numeric-input.qty', () => {
                $('[name=update_cart]').prop('disabled', false).trigger('click');
            })
            .on("click", "button[name=apply_coupon]", function (e) {
                e.preventDefault();

                $(this).closest('.discount-form').find('input[type=submit]').click();
            })
            .on('click', '.quantity .quantity-up', function () {
                let parentBlock = $(this).closest('.quantity');
                let input = parentBlock.find('input[type=number]');
                let max = input.attr('max');
                let oldValue = parseFloat(input.val());
                let newVal = (max !== '' && oldValue >= max) ? oldValue : (oldValue + 1);

                input.val(newVal);
                input.trigger('change');
            })
            .on('click', '.quantity .quantity-down', function () {
                let parentBlock = $(this).closest('.quantity');
                let input = parentBlock.find('input[type=number]');
                let min = input.attr('min');
                let oldValue = parseFloat(input.val());
                let newVal = (oldValue <= min) ? oldValue : (oldValue - 1);

                input.val(newVal);
                input.trigger('change');
            })
            .on('click touch' ,'.gallery-box-title' , function(){
                $('.gallery-box-title').removeClass('active');
                $('.wp-block-gallery').removeClass('active');
                $(this).addClass('active');
                $(this).next().addClass('active');
                $('html,body').animate({
                        scrollTop: $(this).offset().top},
                    'slow');
            });

    },

};
