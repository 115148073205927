import $ from 'jquery';
window.$ = window.jQuery = $;
import './style.scss';

import Map from './classes/map';
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import page from './routes/page';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
    /** All pages */
    common,
    /** Home page */
    home,
    /** About us page */
    page
    /** About Us page, note the change from about-us to aboutUs. */
});

/** Load Map function to build element */
Map.load();

/** Load Events */
$(document).ready(() => routes.loadEvents());
